import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import MediaElement from '../components/MediaElement';

import logo from '../images/logo.svg';
import marker from '../images/marker.svg';
import email from '../images/email.svg';
import lock from '../images/lock.svg';
// import linkedin from '../images/linkedin.svg';
// import twitter from '../images/twitter.svg';
// import dmitry from '../images/dmitry.jpeg';
// import josh from '../images/josh.jpeg';
// import thomas from '../images/thomas.jpeg';

import Seo from '../components/seo';
import ContactForm from '../components/ContactForm';
import ClientOnly from '../components/ClientOnly';
import '../styles/main.scss';

// markup
const IndexPage = () => {
  const sources = [
    {
      src: 'https://mirror.netcologne.de/CCC//congress/2018/h264-hd/35c3-9563-eng-walletfail.mp4',
      type: 'video/mp4',
    },
  ];
  const config = {
    stretching: 'responsive',
  };
  const tracks = {};

  return (
    <>
      <Seo />
      <main>
        <div className="page-section py-5 py-md-10 gray">
          <div className="container">
            <div className="row mb-sm-8">
              <div className="col text-center">
                <a href="/" className="logo">
                  <img width={92} height="auto" src={logo} alt="WALLET.FAIL" />
                  <span className="logo-text">WALLET.FAIL</span>
                  <span className="logo-slogan">Poof goes your crypto ...</span>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <ContactForm
                  id={0}
                  initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    confirm: false,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="page-section py-5 py-md-10">
          <div className="container">
            <div className="row mb-5">
              <div className="col text-center">
                <h3>35C3 PRESENTATION</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ClientOnly>
                  <MediaElement
                    id="player1"
                    mediaType="video"
                    preload="none"
                    controls
                    poster="https://static.media.ccc.de/media/congress/2018/9563-hd_preview.jpg"
                    sources={JSON.stringify(sources)}
                    options={JSON.stringify(config)}
                    tracks={JSON.stringify(tracks)}
                  />
                </ClientOnly>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section py-5 pt-md-10 gray">
          <div className="container">
            <div className="row text-center mb-5">
              <div className="col">
                <h3>OUR TEAM</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="card">
                  <div className="team-member">
                    <div className="team-member-img">
                      <img
                        src={dmitry}
                        width={180}
                        height={180}
                        alt="Dmitry Nedospasov"
                      />
                    </div>
                    <p className="team-member-name">Dmitry Nedospasov</p>
                    <p>
                      Dmitry Nedospasov is a hardware design and security
                      engineer, security researcher, trainer, speaker and
                      reverse-engineerer. In 2014 Dmitry received{' '}
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://users.sec.t-labs.tu-berlin.de/~nedos/Nedospasov_Thesis.pdf"
                      >
                        his PhD
                      </a>{' '}
                      (Dr-Ing.) in IC Security at TU Berlin.
                    </p>
                    <ul className="team-member-social">
                      <li>
                        <a
                          href="https://twitter.com/nedos"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={twitter}
                            width={36}
                            height={36}
                            alt="Twitter"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://linkedin.com/in/nedos"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={linkedin}
                            width={36}
                            height={36}
                            alt="Linkedin"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="card">
                  <div className="team-member">
                    <div className="team-member-img">
                      <img
                        src={josh}
                        width={180}
                        height={180}
                        alt="Josh Datko"
                      />
                    </div>
                    <p className="team-member-name">Josh Datko</p>
                    <p>
                      Josh Datko is an embedded systems engineer, security
                      researcher and former submarine officer. Josh is best
                      known for his{' '}
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/watch?v=hAtoRrxFBWs"
                      >
                        2017 presentation
                      </a>{' '}
                      on insecurities in cryptocurrency hardware wallets.
                    </p>
                    <ul className="team-member-social">
                      <li>
                        <a
                          href="https://twitter.com/cryptotx"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={twitter}
                            width={36}
                            height={36}
                            alt="Twitter"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://linkedin.com/in/joshdatko"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={linkedin}
                            width={36}
                            height={36}
                            alt="Linkedin"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="team-member">
                    <div className="team-member-img">
                      <img
                        src={thomas}
                        width={180}
                        height={180}
                        alt="Thomas Roth"
                      />
                    </div>
                    <p className="team-member-name">Thomas Roth</p>
                    <p>
                      Thomas Roth was named as one of the{' '}
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.forbes.com/profile/thomas-roth/"
                      >
                        30 under 30 in Technology
                      </a>{' '}
                      by the Forbes Magazine. His main focus is on mobile and
                      embedded systems with published research on topics like
                      TrustZone, payment terminals, and embedded security.
                    </p>
                    <ul className="team-member-social">
                      <li>
                        <a
                          href="https://twitter.com/stacksmashing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={twitter}
                            width={36}
                            height={36}
                            alt="Twitter"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://linkedin.com/in/stacksmashing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={linkedin}
                            width={36}
                            height={36}
                            alt="Linkedin"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="page-section py-4 py-md-10 dark">
          <div className="container">
            <div className="row text-center mb-5">
              <div className="col">
                <h3>More from Our Team</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <a
                  className="card"
                  href="https://keylabs.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-top">
                    <StaticImage src="../images/keylabs-logo.png" alt="Keylabs" />
                  </div>
                  <div className="card-bottom">
                    <div>
                      <h4 className="mb-3">Keylabs</h4>
                      <p>
                        Secure Blockchain Solutions, Cryptocurrency Consulting
                        and Blockchain Pentesting.
                      </p>
                    </div>
                    <button
                      className="button primary medium w-100 mt-3"
                      type="button"
                    >
                      See more
                    </button>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <a
                  className="card"
                  href="https://advancedsecurity.training/training/live-wallet-recovery-intro/?event=live-wallet-recovery-intro-spring-2022"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-top">
                    <StaticImage
                      src="../images/live-wallet-recovery-intro.jpg"
                      alt="Advanced Security Training"
                    />
                  </div>
                  <div className="card-bottom">
                    <div>
                      <h4 className="mb-3">Advanced Security Training</h4>
                      <p>
                        Trainings on Hardware Hacking, Wallet Security,
                        Cryptocurrency and Blockchain
                      </p>
                    </div>
                    <button
                      type="text"
                      className="button primary medium w-100 mt-3"
                    >
                      See more
                    </button>
                  </div>
                </a>
              </div>
              <div className="col-lg-4">
                <a
                  className="card"
                  href="https://youtu.be/Y1OBIGslgGM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-top gray">
                    <StaticImage
                      src="../images/wallet-fail-talk.png"
                      alt="Wallet.Fail Talk"
                    />
                  </div>
                  <div className="card-bottom">
                    <div>
                      <h4 className="mb-3">Wallet.Fail Talk</h4>
                      <p>
                        Our talk on the (in-)security of popular consumer Hardware Wallets at 35c3.
                      </p>
                    </div>
                    <button
                      className="button primary medium w-100 mt-3"
                      type="button"
                    >
                      See more
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer py-4">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 offset-lg-2">
              <ul className="row">
                <li className="col-md-5 mb-2 mb-md-0">
                  <a
                    href="https://keylabs.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={marker} width={20} height={20} alt="Location" />
                    KEYLABS
                  </a>
                </li>
                <li className="col-md-4 mb-2 mb-md-0">
                  <a href="mailto:info@wallet.fail">
                    <img src={email} width={20} height={20} alt="Email" />
                    INFO@WALLET.FAIL
                  </a>
                </li>
                <li className="col-md-3">
                  <a href="https://wallet.fail/privacy">
                    <img
                      src={lock}
                      width={20}
                      height={20}
                      alt="Privacy Policy"
                    />
                    PRIVACY POLICY
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
